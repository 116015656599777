import { useEffect } from 'react';

export interface GameInfo {
    tag: string;
    datum: string;
    uhrzeit: string;
    halle: string;
    nr: string;
    heimmannschaft: string;
    gastmannschaft: string;
}

const proxyUrl = "https://lively-pine-3fe1.christoph-85a.workers.dev/"

interface TableFetcherProps {
    setMatchPlans: (matchPlans: { b: any[]; d: any[]; e: any[] }) => void;
}

async function fetchAndParseTable(url: string): Promise<GameInfo[]> {
    try {
        const response = await fetch(proxyUrl + '?url=' + url);
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Selektiere das <h2>-Element mit dem Text "Spielertermine"
        const h2Element = Array.from(doc.querySelectorAll("h2")).find(el => el.textContent?.trim() === "Spieltermine");
        if (h2Element) {
            // Wähle die nächste Tabelle nach dem <h2> Element
            const table = h2Element.nextElementSibling?.tagName === "TABLE" ? h2Element.nextElementSibling : null;

            if (table) {
                const rows = table.querySelectorAll("tr");
                const gameData: GameInfo[] = [];

                // Beginne ab der zweiten Zeile, da die erste Zeile die Header enthält
                rows.forEach((row, index) => {
                    if (index > 0) {
                        const cells = row.querySelectorAll("td");

                        const spanElement = cells[3].querySelector("span");
                        const halleTitle = spanElement?.getAttribute("title") || "";

                        const gameInfo: GameInfo = {
                            tag: cells[0]?.textContent?.trim() || "",
                            datum: cells[1]?.textContent?.trim() || "",
                            uhrzeit: cells[2]?.textContent?.trim() || "",
                            halle: halleTitle,
                            nr: cells[4]?.textContent?.trim() || "",
                            heimmannschaft: cells[5]?.textContent?.trim() || "",
                            gastmannschaft: cells[6]?.textContent?.trim() || "",
                        };
                        gameData.push(gameInfo);
                    }
                });
                return gameData; // Gebe die geparsten Spiel-Daten zurück
            }
        }
    } catch (error) {
        console.error("Fehler beim Fetchen und Parsen:", error);
    }
    return [];
}

const MultipleTableFetcher = ({setMatchPlans}: TableFetcherProps) => {
    useEffect(() => {
        // URLs der verschiedenen Seiten
        const urls: ({ team: string; url: string })[] = [{
            url: "https://hvrheinland-handball.liga.nu/cgi-bin/WebObjects/nuLigaHBDE.woa/wa/teamPortrait?teamtable=2002032&pageState=vorrunde&championship=Rhein%2FWesterwald+24%2F25&group=388727",
            team : 'd'
        },
            {
                url: "https://hvrheinland-handball.liga.nu/cgi-bin/WebObjects/nuLigaHBDE.woa/wa/teamPortrait?teamtable=2009533&pageState=vorrunde&championship=Rhein%2FWesterwald+24%2F25&group=393445",
            team: 'e'
            },
            {url: "https://hvrheinland-handball.liga.nu/cgi-bin/WebObjects/nuLigaHBDE.woa/wa/teamPortrait?teamtable=2007921&pageState=vorrunde&championship=Rhein%2FWesterwald+24%2F25&group=392697",
            team: 'b'}
        ];

        // Funktion zum Laden der Daten von allen URLs
        const loadAllTables = async () => {
            const allGameData = {e: [], d: [], b: []};

            for (const url of urls) {
                // @ts-ignore
                allGameData[url.team] = await fetchAndParseTable(url.url);
            }

            console.log("Alle Spiel-Daten:", allGameData); // Hier kannst du die Daten weiter verarbeiten
            setMatchPlans(allGameData);
        };

        loadAllTables().then(); // Lade die Daten bei der Komponentenmounterung
    }, [setMatchPlans]);

    return null;
};

export default MultipleTableFetcher;
