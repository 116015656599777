import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from "@mui/material/transitions";
import {
    Alert,
    AppBar,
    DialogActions,
    Divider,
    List,
    ListItem,
    ListItemText,
    Slide,
    Toolbar,
    Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import { formatDate } from "../../utilities/Utility";


interface ImportPlayersProps {
    setPlayers: React.Dispatch<React.SetStateAction<any[]>>,
    setSelectedPlayers: React.Dispatch<React.SetStateAction<any[]>>
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const ImportPlayers = ({setPlayers, setSelectedPlayers}: ImportPlayersProps) => {
    const [importedData, setImportedData] = useState<any>();

    const handleClose = () => {
        setImportedData(undefined);
    };

    const handleImport = () => {
        const playersToImport = {...importedData};

        if (playersToImport) {
            setPlayers(playersToImport.players || []);
            setSelectedPlayers(playersToImport.selectedPlayers || []);
            // URL nach dem Import bereinigen (Query-Parameter entfernen)
            const newUrl = window.location.origin + window.location.pathname;
            window.history.replaceState(null, '', newUrl);
        }
        handleClose();
    }

    useEffect(() => {
        // URL-Parameter extrahieren
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get('data');

        if (encodedData) {
            try {
                // Base64 dekodieren
                const jsonData = decodeURIComponent(atob(encodedData));

                // JSON parsen
                const importedData = JSON.parse(jsonData);

                setImportedData(importedData);

            } catch (error) {
                console.error('Fehler beim Importieren der Spieler:', error);
            }
        }
    }, []);

    return <Dialog
        fullScreen
        open={!!importedData}
        onClose={handleClose}
        TransitionComponent={Transition}
    > <AppBar sx={{position: 'relative'}}>
        <Toolbar>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                Spieler importieren
            </Typography>
        </Toolbar>
    </AppBar>
        <List style={{maxHeight: '100%', overflow: 'auto'}}
        >
            {importedData?.players?.map((player: any) => {
                return <>
                    <ListItem>
                        <ListItemText primary={`${player.playerNumber} ${player.firstName} ${player.lastName}`}
                                      secondary={player.birthday ? `Geburtsdatum: ${formatDate(player.birthday)}` : ''}/>
                    </ListItem>
                    <Divider/>
                </>
            })}

        </List>

        <Alert severity="info">Du bist dabei eine Spielerliste zu importieren. Deine Spielerdaten werden überschrieben.</Alert>


        <DialogActions>
            <Button onClick={handleClose}>
                Abbrechen
            </Button>
            <Button onClick={handleImport} autoFocus>
                Spielerdaten importieren
            </Button>
        </DialogActions>
    </Dialog>;
}
