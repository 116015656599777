import Dialog from "@mui/material/Dialog";
import { AppBar, DialogActions, Slide, Toolbar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "../PDF/PDF";
import { Player } from "../../Types/Types";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PDFModalProps {
    open: boolean;
    onClose: () => void;
    sortedPlayers: Player[];
    teamName: string;
    matchNumber: string;
    matchDate: string;
    matchTime: string;
    officials: [
        offA: { firstName: string; lastName: string },
        offB: { firstName: string; lastName: string },
        offC: { firstName: string; lastName: string },
        offD: { firstName: string; lastName: string },
    ]
}

export const PDFModal = ({open, sortedPlayers, teamName, matchDate, matchNumber, matchTime, officials, onClose}: PDFModalProps) => {

    return <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
    > <AppBar sx={{position: 'relative'}}>
        <Toolbar>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                PDF anzeigen
            </Typography>
        </Toolbar>
    </AppBar>
        <PDFViewer style={{width: '100%', height: '100vh'}}>
            <PDF sortedPlayers={sortedPlayers} teamName={teamName}
                 matchNumber={matchNumber}
                 matchDate={matchDate}
                 matchTime={matchTime}
                 officials={officials}
            />
        </PDFViewer>

        <DialogActions>
            <Button onClick={onClose}>
                Schließen
            </Button>

        </DialogActions>
    </Dialog>;
}
