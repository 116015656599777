import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, Chip
} from '@mui/material';
import { GameInfo } from "../../Components/TableFetcher/TableFetcher";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

interface MatchTableProps {
    matchPlans: { b: GameInfo[], e: GameInfo[], d: GameInfo[] };
}

export const MatchTable = ({ matchPlans }: MatchTableProps) => {
    const [homeGames, setHomeGames] = useState<boolean>(false);
    const [awayGames, setAwayGames] = useState<boolean>(false);
    const [stadium, setStadium] = useState<{ lahnstein: boolean, horchheim: boolean }>({ lahnstein: false, horchheim: false });

    const [filteredGames, setFilteredGames] = useState<GameInfo[]>([]);

    const { type } = useParams<{ type: string }>();

    const [nextGame, setNextGame] = useState<GameInfo | null>(null);

    // Funktion zur Überprüfung, ob das Spiel in der Vergangenheit liegt (nur Datum wird geprüft)
    const isPastGame = (game: GameInfo) => {
        const [day, month, year] = game.datum.split('.').map(Number); // Datum in [Tag, Monat, Jahr] zerlegen
        const gameDate = new Date(year, month - 1, day); // Erstelle ein JavaScript-Datum

        const today = new Date(); // Aktuelles Datum

        // Vergleiche nur das Datum (ignoriere die Uhrzeit)
        return gameDate < new Date(today.getFullYear(), today.getMonth(), today.getDate());
    };

    // Funktion zur Überprüfung, ob das Spiel am heutigen Tag stattfindet
    const isToday = (game: GameInfo) => {
        const [day, month, year] = game.datum.split('.').map(Number);
        const gameDate = new Date(year, month - 1, day);

        const today = new Date();

        return gameDate.getDate() === today.getDate() &&
            gameDate.getMonth() === today.getMonth() &&
            gameDate.getFullYear() === today.getFullYear();
    };

    // Bestimme das nächste kommende Spiel
    useEffect(() => {
        //@ts-ignore
        const futureGames = matchPlans[type].filter(game => !isPastGame(game) || isToday(game)); // Spiele in der Zukunft oder heute
        setFilteredGames(futureGames);

        if (futureGames.length > 0) {
            setNextGame(futureGames[0]); // Markiere das nächste Spiel
        }
    }, [matchPlans, type]);


    const onClickStadiumHorchheim = () => {
        setStadium(prev => ({ lahnstein: false, horchheim: !prev.horchheim }));
        setAwayGames(false);
    };

    const onClickStadiumLahnstein = () => {
        setStadium(prev => ({ horchheim: false, lahnstein: !prev.lahnstein }));
        setAwayGames(false);
    };

    const onClickHomeGames = () => {
        setHomeGames(prev => !prev);
        setAwayGames(false); // Setze den Auswärtsfilter zurück
    };

    const onClickAwayGames = () => {
        setAwayGames(prev => !prev);
        setStadium(prev => ({ horchheim: false, lahnstein: false}));
        setHomeGames(false); // Setze den Heimspiel-Filter zurück
    };

    const checkType = (type: string) => {
        return ['e', 'd', 'b'].includes(type);
    };

    // Hauptfilter-Logik in einem einzigen useEffect
    useEffect(() => {
        if (type && checkType(type) && matchPlans) {
            // @ts-ignore
            let games = matchPlans[type];

            // Heimspiele-Filter
            if (homeGames) {
                games = games.filter((game: GameInfo) => game.heimmannschaft.includes('HSG Horchheim/Lahnstein'));
            }

            // Auswärtsspiele-Filter
            if (awayGames) {
                games = games.filter((game: GameInfo) => !game.heimmannschaft.includes('HSG Horchheim/Lahnstein'));
            }

            // Horchheim-Filter
            if (stadium.horchheim) {
                games = games.filter((game: GameInfo) => game.halle.includes('Horchheim'));
            }

            // Lahnstein-Filter
            if (stadium.lahnstein) {
                games = games.filter((game: GameInfo) => game.halle.includes('Lahnstein'));
            }

            setFilteredGames(games);
        }
    }, [homeGames, awayGames, stadium, matchPlans, type]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
            <Chip
                label="Heimspiele"
                color="primary"
                variant={homeGames ? 'filled' : "outlined"}
                onClick={onClickHomeGames}
            />
            <Chip
                label="Auswärtsspiele"
                color="primary"
                variant={awayGames ? 'filled' : "outlined"}
                onClick={onClickAwayGames}
            />
            <Chip
                label="Horchheim"
                color="primary"
                variant={stadium.horchheim ? 'filled' : "outlined"}
                onClick={onClickStadiumHorchheim}
            />
            <Chip
                label="Lahnstein"
                color="primary"
                variant={stadium.lahnstein ? 'filled' : "outlined"}
                onClick={onClickStadiumLahnstein}
            />
            </div>

            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Heim</TableCell>
                            <TableCell>Gegner</TableCell>
                            <TableCell>Adresse</TableCell>
                            <TableCell>Datum</TableCell>
                            <TableCell>Uhrzeit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredGames.length > 0 ? filteredGames.map((game, index) => {
                            const isPast = isPastGame(game) && !isToday(game); // Vergangene Spiele, die nicht heute sind
                            const isNext = nextGame && game === nextGame; // Ist das das nächste Spiel?

                            return (
                                <TableRow
                                    key={index}
                                    style={{
                                        opacity: isPast ? 0.5 : 1, // Verblasst vergangene Spiele
                                        height: isPast ? '20px' : 'auto', // "Klappt" die Höhe von vergangenen Spielen ein
                                        backgroundColor: isNext ? 'rgb(255, 244, 229)' : 'transparent', // Markiere das nächste Spiel
                                    }}
                                >
                                    <TableCell>{game.heimmannschaft}</TableCell>
                                    <TableCell>{game.gastmannschaft}</TableCell>
                                    <TableCell>{game.halle}</TableCell>
                                    <TableCell>{game.datum?.replaceAll('-', '.')}</TableCell>
                                    <TableCell>{game.uhrzeit}</TableCell>
                                </TableRow>
                            )}) :
                            [...Array(4)].map((_, i) => (
                                <TableRow key={i}>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
