import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Fab } from "@mui/material";
import React from "react";

interface PDFButtonProps {
    onClick: () => void
}
export const PDFButton = (props: PDFButtonProps) => {
    return <Fab
        color="primary"
        aria-label="download"
        onClick={props.onClick}
        className={'pdf-button'}
    >
        <PictureAsPdfIcon/>
    </Fab>
}
