import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
export const BackButton = () => {

    const navigate = useNavigate();
    const location = useLocation();

    // Berechne die nächsthöhere URL
    const handleBack = () => {
        const pathSegments = location.pathname.split('/').filter(Boolean); // Pfadsegmente als Array

        if (pathSegments.length > 1) {
            // Entferne das letzte Segment, um zur nächsthöheren URL zu gelangen
            const higherUrl = '/' + pathSegments.slice(0, -1).join('/');
            navigate(higherUrl);
        } else {
            // Wenn du auf der obersten Ebene bist (z.B. "/table"), gehe zur Startseite ("/")
            navigate('/');
        }
    };

    const isNotHomePage = location.pathname !== '/';

    return isNotHomePage ?
        <Button
            style={{ margin: '10px 0', alignSelf: 'flex-start' }}
            variant="contained"
            onClick={handleBack} // Zur Startseite navigieren
        >
            <ArrowBackIosIcon />
        </Button> : <></>
}
