import React from 'react';
import { pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { Player } from "../../Types/Types";
import DownloadIcon from '@mui/icons-material/Download';
import { Fab } from "@mui/material";
import PDF from "../PDF/PDF";
import { formatDate } from "../../utilities/Utility";


interface DownloadButtonProps {
    sortedPlayers: Player[];
    teamName: string;
    matchNumber: string;
    matchDate: string;
    matchTime: string;
    officials: [
        offA: { firstName: string; lastName: string },
        offB: { firstName: string; lastName: string },
        offC: { firstName: string; lastName: string },
        offD: { firstName: string; lastName: string },
    ]
}

export const DownloadButton = (props: DownloadButtonProps) => {
    const handleDownload = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
            const doc = <PDF matchDate={props.matchDate} matchNumber={props.matchNumber} matchTime={props.matchTime}
                             officials={props.officials} sortedPlayers={props.sortedPlayers}
                             teamName={props.teamName}/>;
            const blob = await pdf(doc).toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Spielerliste ${formatDate(props.matchDate)}.pdf`;
            link.click();
            URL.revokeObjectURL(url); // URL freigeben
    };

    return (

            <PDFDownloadLink document={<PDF matchDate={props.matchDate} matchNumber={props.matchNumber} matchTime={props.matchTime}
                                            officials={props.officials} sortedPlayers={props.sortedPlayers}
                                            teamName={props.teamName}/>}>
                {() =>
                     <Fab
                        color="primary"
                        aria-label="download"
                        onClick={handleDownload}
                        className={'download-button'}
                    >
                        <DownloadIcon />
                    </Fab>


                }
            </PDFDownloadLink>
    );
};

