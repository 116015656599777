import {Grid2, TextField, Typography} from "@mui/material";
import React from "react";

interface FormProps {
    headline: string;
    formElement: {label: string, value: string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, type?: string, InputLabelProps?: any}[];
}

export const Form = ({headline, formElement}: FormProps) => {
    return <>
        <Typography variant="h5" gutterBottom>{headline}</Typography>
        <Grid2 container spacing={2}>
            {formElement.map((element, index) => {
                return <Grid2 size={6} key={element.label}>
                    <TextField
                        fullWidth
                        label={element.label}
                        value={element.value}
                        onChange={element.onChange}
                        type={element.type}
                        InputLabelProps={element.InputLabelProps}
                    />
                </Grid2>
            })}
        </Grid2>
    </>
}
