import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

const teams: { value: 'e' | 'd' | 'b', name: string }[] = [{value: 'e', name: 'E-Jugend'}, {
    value: 'd',
    name: 'D-Jugend'
}, {value: 'b', name: 'B-Jugend'}]

interface TeamTableProps {
    loading: boolean;
}


export const TeamTable = ({loading}: TeamTableProps) => {

    const navigate = useNavigate();

    const handleClick = (value: string) => {
        navigate(value);
    }

    return <TableContainer component={Paper}
                           sx={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: '50px auto',   // Zentrierung der Tabelle
                               maxWidth: 600,         // Maximale Breite der Tabelle
                               borderRadius: '15px',  // Abgerundete Ecken
                               border: '2px solid #ddd' // Rahmen der Tabelle
                           }}>
        <Table sx={{minWidth: 300}}>
            <TableBody>
                {teams.map((row, index) => (
                    loading ? <TableRow key={index}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5', // Hover-Effekt
                                            },
                                            transition: 'background-color 0.3s ease', // Sanfter Übergang beim Hover
                                        }}
                        >
                            <TableCell align="center" sx={{padding: '20px'}}>
                                <Skeleton/></TableCell>
                        </TableRow>
                        : <TableRow onClick={() => handleClick(row.value)}
                                    key={index}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5', // Hover-Effekt
                                        },
                                        transition: 'background-color 0.3s ease', // Sanfter Übergang beim Hover
                                    }}
                        >
                            <TableCell align="center" sx={{padding: '20px'}}>
                                {row.name}</TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}
