import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import EventNoteIcon from '@mui/icons-material/EventNote';

export const Home = () => {
    return <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            gap: 3,
            height: '100%'
        }}
    >


        <Button
            component={Link}
            to="/team-table"
            variant="outlined"
            startIcon={<EventNoteIcon style={{ fontSize: 40 }} />}
            sx={{
                border: '0.5px solid black',
                color: 'black',
                padding: '20px 40px',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textTransform: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                    backgroundColor: '#f0f0f0',
                    borderColor: 'black',
                    transform: 'scale(1.05)',
                },
            }}
        >
            Termine
        </Button>

        <Button
            component={Link}
            to="/player-list"
            variant="outlined"
            startIcon={<SportsHandballIcon style={{ fontSize: 40 }} />}
            sx={{
                border: '0.5px solid black',
                color: 'black',
                padding: '20px 40px',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                textTransform: 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                    backgroundColor: '#f0f0f0',
                    borderColor: 'black',
                    transform: 'scale(1.05)',
                },
            }}
        >
            Spielerliste
        </Button>
    </Box>
}
