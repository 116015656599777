import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { Alert } from "@mui/material";
import { InstallMobile } from "@mui/icons-material";
import { Analytics } from "@vercel/analytics/react"
import { PDFCreation } from "./Pages/PDFCreation/PDFCreation";
import hsg from './Image/hsg2.png';
import { SpeedInsights } from "@vercel/speed-insights/react"
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from "./Pages/Home/Home";
import { MatchTable } from "./Pages/MathTable/MatchTable";
import { BackButton } from "./Components/BackButton/BackButton";
import TableFetcher from "./Components/TableFetcher/TableFetcher";
import { TeamTable } from "./Pages/TeamTable/TeamTable";

const App: React.FC = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [installable, setInstallable] = useState<boolean>(false);
    const [matchPlans, setMatchPlans] = useState<any>();

    useEffect(() => {
        const handler = (e: any) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setInstallable(true);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('beforeinstallprompt', handler);
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                setInstallable(false);
            });
        }
    };

    return (
        <Router>
            <Analytics/>
            <SpeedInsights/>
            <TableFetcher setMatchPlans={setMatchPlans}/>
            <Alert severity="warning" icon={false}>
                <img src={hsg} width={'20%'} alt={'Logo'}/>
            </Alert>

            {installable && (
                <Button style={{width: '100%'}} variant="contained" startIcon={<InstallMobile/>}
                        onClick={handleInstallClick}>Installiere diese App</Button>
            )}
            <div style={{padding: '1rem', height: '100%'}}>
                <BackButton/>
                <Routes>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/team-table'} element={<TeamTable loading={!matchPlans}/>}/>
                    <Route path="/team-table/:type" element={<MatchTable matchPlans={matchPlans}/>}/>
                    <Route path={'/player-list'} element={<PDFCreation/>}/>
                </Routes>
            </div>
        </Router>
    );
};

export default App;
