import ShareIcon from '@mui/icons-material/Share';
import { Fab, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { Player } from "../../Types/Types";

interface ShareButtonProps {
    players: Player[],
    selectedPlayers: Player[]
}

export const ShareButton = ({players, selectedPlayers}: ShareButtonProps) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleExport = () => {
        // Daten in einem Objekt zusammenführen
        const dataToExport = {
            players,
            selectedPlayers,
        };

        // JSON-Daten serialisieren und Base64 kodieren
        const jsonData = JSON.stringify(dataToExport);
        const encodedData = btoa(encodeURIComponent(jsonData));

        // URL erstellen
        const link = `${window.location.origin}/player-list/?data=${encodedData}`;

        // Mobile Geräte: Web Share API
        if (navigator.share) {
            navigator.share({
                title: 'Spieler Liste teilen',
                text: 'Hier ist der Link zu meiner Spieler Liste:',
                url: link,
            })
                .then(() => console.log('Link erfolgreich geteilt'))
                .catch((error) => console.log('Fehler beim Teilen des Links:', error));
        } else {
            // Fallback für den Browser: Link in die Zwischenablage kopieren
            navigator.clipboard.writeText(link).then(() => {
                setSnackbarOpen(true);
            }, (error) => {
                console.error('Fehler beim Kopieren des Links:', error);
            });
        }
    };

     return <><Fab
        color="primary"
        aria-label="share"
        onClick={handleExport}
        className={'share-button'}
    >
        <ShareIcon/>
    </Fab>
         <Snackbar
             open={snackbarOpen}
             autoHideDuration={3000}
             onClose={() => setSnackbarOpen(false)}
             message="Link wurde in die Zwischenablage kopiert!"
         />
         </>
}
