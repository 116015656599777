import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Player } from "../../Types/Types";
import font from "./font/Times New Roman.ttf";
import boldFont from './font/Times New Roman Bold.ttf';

import headerImage from '../../Image/HVR_1_400x150px.png-d1dwxsnvhxz78nh.png';
import { formatDate } from "../../utilities/Utility";

Font.register({ family: 'TimesNewRoman', fonts: [
        {src: font},
        {src: boldFont, fontStyle: 'bold', fontWeight: 'bold'}
    ]});



interface PDFProps {
    sortedPlayers: Player[];
    teamName: string;
    matchNumber: string;
    matchDate: string;
    matchTime: string;
    officials: [
        offA: { firstName: string; lastName: string },
        offB: { firstName: string; lastName: string },
        offC: { firstName: string; lastName: string },
        offD: { firstName: string; lastName: string },
    ]
}

// Erstelle die Styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: "TimesNewRoman",
        fontStyle: 'bold',
        fontSize: '11px'
    },
    section: {
        marginBottom: 10,
    },
    headerBox: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        padding: 10,
        marginBottom: 20,
    },
    title: {
        fontSize: 18,
    },
    smallText: {
        fontSize: 10,
    },
    logo: {
        width: 50,
        height: 50,
        backgroundColor: '#ccc', // Platzhalter für das Logo
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        fontSize: '14px',
        fontStyle: 'bold'
    },
    leftInfo: {
        textAlign: 'left',
        width: "73%"
    },
    infoText:{
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem'
    },
    rightInfo: {},
    table: {
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: 0.5,
        borderColor: '#000',
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
    },
    lastTableRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableFirstCol: {
        width: '5%',
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
    },
    tableSecondCol: {
        width: '7%',
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
        borderLeftStyle: "solid",
        borderLeftWidth: 0.5
    },
    tableThirdCol: {
        width: '30%',
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
        borderLeftStyle: "solid",
        borderLeftWidth: 0.5
    },
    tableFourthCol: {
        width: '30%',
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
        borderLeftStyle: "solid",
        borderLeftWidth: 0.5
    },
    tableFifthCol: {
        width: '20%',
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
        borderLeftStyle: "solid",
        borderLeftWidth: 0.5
    },
    tableSixthCol: {
        textAlign: 'left', // Links ausgerichtet
        padding: 5,
        fontSize: 10,
        borderLeftStyle: "solid",
        borderLeftWidth: 0.5
    },
    signature: {
        flexDirection: 'row',
        gap: '50px',
        marginTop: 30,
    },
    signatureText: {
      fontSize: '10px'
    },
    signatureTextLeft: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        fontSize: "12px",
        paddingTop: "3px"
    },
    signatureTextRight: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        fontSize: "12px",
        paddingTop: "3px"
    },
    noBorderText: {
        fontSize: 10,
        padding: 5,
    },
    noBorderColumnWrapper: {
        display: "flex",
        flexDirection: 'row',
        height: '20px',
    },
    noBorderColumnLeft: {
        width: "92%",
        fontSize: '11px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    noBorderColumnRight: {
        display: "flex",
        flexDirection: 'row',
        fontSize: '11px',
        position: 'relative'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    box: {
        justifyContent: 'space-between',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#000',
        fontSize: "12px",
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        width: '200px'
    },
    image: {
        height: 50,
    },
    normalText: {
        fontFamily: "Helvetica",
        fontStyle: 'normal'
    }
});

// Hauptkomponente
const PDF = (props: PDFProps) => {
    const totalRows = 20; // Gesamtanzahl der Zeilen
    const emptyRows = totalRows - props.sortedPlayers.length; // Anzahl der leeren Zeilen

    // Leere Zeilen erstellen
    const emptyRowsArray = Array(emptyRows).fill(null);




    return <Document>
            <Page size="A4" style={styles.page}>
                {/* Kopfzeile */}
                <View style={styles.header}>
                    <View style={styles.box}>
                        <Text>SPIELERLISTE</Text>
                        <Text style={styles.normalText}>HSG Horchheim/Lahnstein</Text>
                        <Text>(Verein)</Text>
                    </View>
                    <Image style={styles.image} src={headerImage}/>
                </View>

                {/* Informationen zu Mannschaft und Spiel-Nr., Datum und Uhrzeit */}
                <View style={styles.infoRow}>
                    <View style={styles.leftInfo}>
                        <View style={styles.infoText}>
                        <Text>Mannschaft:</Text><Text style={styles.normalText}> {props.teamName}</Text>
                        </View>
                        <View style={styles.infoText}>
                            <Text>Spiel-Nr.:</Text> <Text style={styles.normalText}> {props.matchNumber}</Text>
                        </View>
                    </View>
                    <View style={styles.rightInfo}>
                        <View style={styles.infoText}>
                            <Text>Datum:</Text>
                            <Text style={styles.normalText}> {props.matchDate ? formatDate(props.matchDate) : ''}</Text>
                        </View>
                        <View style={styles.infoText}>
                            <Text>Uhrzeit:</Text>
                            <Text style={styles.normalText}> {props.matchTime}</Text>
                        </View>
                    </View>
                </View>

                {/* Tabelle */}
                <View style={styles.table}>
                    <View style={styles.lastTableRow}>
                        <Text style={styles.tableFirstCol}>Lfd. Nr.</Text>
                        <Text style={styles.tableSecondCol}>Trikot-
                            Nr.</Text>
                        <Text style={styles.tableThirdCol}>Name</Text>
                        <Text style={styles.tableFourthCol}>Vorname</Text>
                        <Text style={styles.tableFifthCol}>Geburtsdatum</Text>
                        <Text style={styles.tableSixthCol}>Torwart</Text>
                    </View>
                </View>

                <View style={styles.noBorderColumnWrapper}>
                    {/* Eintragungen Hinweis ohne Rahmen */}
                    <View style={styles.noBorderColumnLeft}>
                        <Text>
                        Eintragungen nach Trikot-Nr. Aufsteigend eintragen
                        </Text>
                    </View>
                    <View style={styles.noBorderColumnRight}>
                        <Text>
                        ankreuzen
                        </Text>
                    </View>
                </View>

                {/* Tabelle - 20 leere Zeilen */}
                <View style={{...styles.table, ...{marginBottom: "15px"}}}>
                    {props.sortedPlayers.map((player, index) => {
                        return <View style={index + 1 < 20 ? styles.tableRow : styles.lastTableRow} key={index}>
                            <Text style={styles.tableFirstCol}>{index + 1}</Text>
                            <Text style={{...styles.tableSecondCol, ...styles.normalText}}>{player.playerNumber}</Text>
                            <Text style={{...styles.tableThirdCol, ...styles.normalText}}>{player.lastName}</Text>
                            <Text style={{...styles.tableFourthCol, ...styles.normalText}}>{player.firstName}</Text>
                            <Text style={{...styles.tableFifthCol, ...styles.normalText}}>{player.birthday ? formatDate(player.birthday) : ''}</Text>
                            <Text style={{...styles.tableSixthCol, ...styles.normalText}}>{player.keeper ? "X" : ""}</Text>
                        </View>
                    })}
                    {emptyRowsArray.map((_, i) => (
                        <View style={i + 1 < emptyRowsArray.length ? styles.tableRow : styles.lastTableRow}
                              key={i + props.sortedPlayers.length}>
                            <Text style={styles.tableFirstCol}>{props.sortedPlayers.length + 1 + i}</Text>
                            <Text style={styles.tableSecondCol}> </Text>
                            <Text style={styles.tableThirdCol}> </Text>
                            <Text style={styles.tableFourthCol}> </Text>
                            <Text style={styles.tableFifthCol}> </Text>
                            <Text style={styles.tableSixthCol}> </Text>
                        </View>
                    ))}
                </View>

                {/* Fußzeile mit Off. A, Off. B, Off. C, Off. D in einer 4-zeiligen, 5-spaltigen Tabelle */}
                <View style={styles.table}>
                    {[...Array(4)].map((_, i) => (
                        <View style={i + 1 < 4 ? styles.tableRow : styles.lastTableRow} key={i}>
                            <Text style={styles.tableFirstCol}></Text>
                            <Text style={styles.tableSecondCol}>{`Off. ${String.fromCharCode(65 + i)}`}</Text>
                            <Text style={{...styles.tableThirdCol, ...styles.normalText}}>{props.officials[i].firstName}</Text>
                            <Text style={{...styles.tableFourthCol, ...styles.normalText}}>{props.officials[i].lastName}</Text>
                            <Text style={styles.tableFifthCol}></Text>
                        </View>
                    ))}
                </View>

                {/* Unterschrift und Datum */}
                <View style={styles.signature}>
                    <div>
                        <Text style={styles.signatureText}> {props.matchDate ? formatDate(props.matchDate) : ''}                      </Text>
                        <Text style={styles.signatureTextLeft}>Datum</Text>
                    </div>
                    <div>
                        <Text style={styles.signatureText}>                                                                  </Text>
                        <Text style={styles.signatureTextRight}>Unterschrift MV</Text>
                    </div>
                </View>
            </Page>
        </Document>
};

export default PDF;
